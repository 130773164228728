import ConstrainWidth from 'src/components/ConstrainWidth';
import SectionContainer from 'src/components/SectionContainer';
import { useState } from 'react';
import {
  ImportJobAzureBlob,
  ImportJobFile,
  ImportJobS3,
  ImportJobType,
  ImportJobUrl,
} from 'types';
import FileSource from './FileSource';
import URLSource from './URLSource';
import AzureStorageSource from './AzureStorageSource';
import S3Source from './S3Source';
import { Button, Heading } from '@crate.io/crate-gc-admin';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { clusterImport } from 'src/constants/paths';
import { JOB_ID_QUERY_PARAM, SOURCE_QUERY_PARAM } from './constants';
import { useGetClustersIdImportJob } from 'src/swrTsHooks';
import LoadingContainer from 'src/components/LoadingContainer';
import StepLayout from 'src/components/StepLayout';
import { SourceStep } from './common/SourceStep';

function ClusterImportFile() {
  const navigate = useNavigate();
  const retryJobId = new URLSearchParams(location.search).get(JOB_ID_QUERY_PARAM);
  const initialSource = new URLSearchParams(location.search).get(SOURCE_QUERY_PARAM);
  const { clusterId, organizationId, projectId } = useParams();
  const [sourceType, setSourceType] = useState<ImportJobType | null>(
    initialSource ? (initialSource as ImportJobType) : null,
  );

  // swr
  const { data: retryJob, isLoading: isLoadingRetryJob } = useGetClustersIdImportJob(
    clusterId,
    retryJobId,
  );

  const renderSourceForm = () => {
    switch (sourceType) {
      case null:
        return (
          <StepLayout
            stepIndex={0}
            setStepIndex={() => {}}
            steps={[
              {
                title: (
                  <FormattedMessage id="cluster.clusterImportFile.sourceStep.title" />
                ),
                renderContent: () => (
                  <SourceStep
                    sourceType={sourceType}
                    onChangeSourceType={type => {
                      setSourceType(type);
                    }}
                  />
                ),
              },
            ]}
          />
        );
      case 'file':
        return (
          <FileSource
            sourceType={sourceType}
            setSourceType={setSourceType}
            job={retryJob as ImportJobFile | undefined}
          />
        );
      case 'url':
        return (
          <URLSource
            sourceType={sourceType}
            setSourceType={setSourceType}
            job={retryJob as ImportJobUrl | undefined}
          />
        );
      case 'azureblob':
        return (
          <AzureStorageSource
            sourceType={sourceType}
            setSourceType={setSourceType}
            job={retryJob as ImportJobAzureBlob | undefined}
          />
        );
      case 's3':
        return (
          <S3Source
            sourceType={sourceType}
            setSourceType={setSourceType}
            job={retryJob as ImportJobS3 | undefined}
          />
        );
    }
  };

  return (
    <ConstrainWidth>
      <SectionContainer className="absolute h-full w-full overflow-x-auto">
        <LoadingContainer
          loading={isLoadingRetryJob}
          render={() => {
            if (retryJob) {
              setSourceType(retryJob.type);
            }
            return (
              <>
                <div className="flex items-center justify-between">
                  <Heading level={Heading.levels.h2}>
                    <FormattedMessage id="cluster.clusterImportFile.title" />
                  </Heading>

                  <Button
                    onClick={() => {
                      navigate(
                        clusterImport.build({
                          clusterId,
                          organizationId,
                          projectId,
                        }),
                      );
                    }}
                  >
                    <FormattedMessage id="common.cancel" />
                  </Button>
                </div>
                <div className="mt-2">{renderSourceForm()}</div>
              </>
            );
          }}
        />
      </SectionContainer>
    </ConstrainWidth>
  );
}

export default ClusterImportFile;
