import { Checkbox, Form, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import INPUT_SANITIZATION from '../../../../../constants/inputSanitization';
import InfoPopover from 'src/components/InfoPopover';
import { Button } from '@crate.io/crate-gc-admin';
import { FIELD_NAMES, SinkDetailsStepForm } from '../../constants';
import ConstrainStepWidth from '../ConstrainStepWidth';

export type SinkDetailsStepProps = {
  isSubmitting: boolean;
  defaultValues: SinkDetailsStepForm;
  onConfirm: (result: SinkDetailsStepForm) => void;
};

function SinkDetailsStep({
  isSubmitting,
  defaultValues,
  onConfirm,
}: SinkDetailsStepProps) {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm<SinkDetailsStepForm>();

  const onFormFinish = (values: SinkDetailsStepForm) => {
    onConfirm(values);
  };

  return (
    <ConstrainStepWidth>
      <Form<SinkDetailsStepForm>
        autoComplete="off"
        form={form}
        initialValues={{
          ...defaultValues,
        }}
        layout="vertical"
        name="import-data-sink-form"
        id="import-data-sink-form"
        aria-label="import-data-sink-form"
        onFinish={onFormFinish}
        requiredMark="optional"
      >
        <Form.Item<SinkDetailsStepForm>
          colon={false}
          label={
            <FormattedMessage id="cluster.clusterImportFile.sinkDetailsStep.tableLabel" />
          }
          name={[FIELD_NAMES.DESTINATION_NAMESPACE, FIELD_NAMES.TABLE_NAME]}
          rules={[INPUT_SANITIZATION.TABLE_NAME]}
        >
          <Input
            placeholder={formatMessage({
              id: 'cluster.clusterImportFile.sinkDetailsStep.tableNamePlaceholder',
            })}
          />
        </Form.Item>

        {/* create or update table */}
        <Form.Item<SinkDetailsStepForm>
          className="col-span-2"
          colon={false}
          name={[FIELD_NAMES.DESTINATION_NAMESPACE, FIELD_NAMES.CREATE_TABLE]}
          valuePropName="checked"
        >
          <Checkbox>
            <FormattedMessage id="cluster.clusterImportFile.sinkDetailsStep.schemaEvolution" />

            <span className="ml-2">
              <InfoPopover
                content={
                  <div>
                    <FormattedMessage id="cluster.clusterImportFile.sinkDetailsStep.schemaEvolutionHelp" />
                  </div>
                }
                title={
                  <FormattedMessage id="cluster.clusterImportFile.sinkDetailsStep.schemaEvolution" />
                }
              />
            </span>
          </Checkbox>
        </Form.Item>

        <div className="mt-4 flex">
          <Button
            type={Button.types.SUBMIT}
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            <FormattedMessage id="cluster.clusterImportFile.sinkDetailsStep.import" />
          </Button>
        </div>
      </Form>
    </ConstrainStepWidth>
  );
}

export default SinkDetailsStep;
