import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@crate.io/crate-gc-admin';
import logoAmazonS3 from '../../../../assets/logo_amazon_s3.png';
import logoMongoDB from '../../../../assets/logo_mongodb.svg';
import logoAzureStorage from '../../../../assets/logo_azure_2021.svg';
import logoDebezium from '../../../../assets/logo_debezium.svg';
import {
  DEBEZIUM_INTEGRATION_LINK,
  INTEGRATION_STATUS,
  INTEGRATION_TYPE,
} from '../constants';
import { clusterImportFile } from '../../../../constants/paths';
import { SOURCE_QUERY_PARAM, SOURCES } from '../../ClusterImportFile/constants';

function useIntegrations() {
  const { formatMessage } = useIntl();
  const { clusterId, projectId, organizationId } = useParams();

  const renderImportAction = source => {
    return (
      <Link
        to={`${clusterImportFile.build({ organizationId, projectId, clusterId })}?${SOURCE_QUERY_PARAM}=${source}`}
      >
        <Button kind={Button.kinds.PRIMARY}>
          <FormattedMessage id="clusterIntegrations.import" />
        </Button>
      </Link>
    );
  };

  const renderExternalLink = (externalLink, label) => {
    return (
      <a href={externalLink} target="_blank" rel="noreferrer">
        <Button kind={Button.kinds.TERTIARY} size={Button.sizes.SMALL}>
          {label}
        </Button>
      </a>
    );
  };

  return [
    {
      name: formatMessage({
        id: 'clusterIntegrations.integrations.mongoDb.name',
      }),
      description: formatMessage({
        id: 'clusterIntegrations.integrations.mongoDb.description',
      }),
      status: INTEGRATION_STATUS.DEVELOPMENT,
      type: INTEGRATION_TYPE.MANAGED,
      logo: logoMongoDB,
    },
    {
      name: formatMessage({
        id: 'clusterIntegrations.integrations.automatedAmazonS3.name',
      }),
      description: formatMessage({
        id: 'clusterIntegrations.integrations.automatedAmazonS3.description',
      }),
      status: INTEGRATION_STATUS.DEVELOPMENT,
      type: INTEGRATION_TYPE.MANAGED,
      logo: logoAmazonS3,
    },
    {
      name: formatMessage({
        id: 'clusterIntegrations.integrations.amazonS3.name',
      }),
      description: formatMessage({
        id: 'clusterIntegrations.integrations.amazonS3.description',
      }),
      type: INTEGRATION_TYPE.REGULAR,
      logo: logoAmazonS3,
      action: renderImportAction(SOURCES.S3),
    },
    {
      name: formatMessage({
        id: 'clusterIntegrations.integrations.azureStorage.name',
      }),
      description: formatMessage({
        id: 'clusterIntegrations.integrations.azureStorage.description',
      }),
      type: INTEGRATION_TYPE.REGULAR,
      logo: logoAzureStorage,
      action: renderImportAction(SOURCES.AZUREBLOB),
    },
    {
      name: formatMessage({
        id: 'clusterIntegrations.integrations.debezium.name',
      }),
      description: formatMessage({
        id: 'clusterIntegrations.integrations.debezium.description',
      }),
      type: INTEGRATION_TYPE.REGULAR,
      logo: logoDebezium,
      action: renderExternalLink(
        DEBEZIUM_INTEGRATION_LINK,
        formatMessage({
          id: 'clusterIntegrations.openTutorial',
        }),
      ),
    },
  ];
}

export default useIntegrations;
