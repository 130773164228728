import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Popconfirm } from 'antd';
import ConstrainWidth from 'src/components/ConstrainWidth';
import LoadingContainer from 'src/components/LoadingContainer';
import { apiDelete } from 'src/api';
import { useGetClustersIdImportJobs } from 'src/swrTsHooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Heading } from '@crate.io/crate-gc-admin';
import ImportJobsList from './ImportJobsList';
import { ImportJob, ImportJobId } from 'types';
import { clusterImportFile } from 'src/constants/paths';
import SectionContainer from 'src/components/SectionContainer';
import { JOB_ID_QUERY_PARAM } from '../ClusterImportFile/constants';

function ClusterImport() {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { clusterId, organizationId, projectId } = useParams();

  // clear completed import jobs
  const [importJobsToBeCleared, setImportJobsToBeCleared] = useState<ImportJobId[]>(
    [],
  );

  // swr
  const {
    data: importJobs,
    mutate: mutateImportJobs,
    isLoading: isLoadingImportJobs,
  } = useGetClustersIdImportJobs(clusterId!);

  const handleImportJobDelete = async (importJob: { id: ImportJobId }) => {
    await apiDelete(`/api/v2/clusters/${clusterId}/import-jobs/${importJob.id}/`);

    mutateImportJobs(
      importJobs!.filter(existingImportJob => existingImportJob.id !== importJob.id),
    );
  };

  const handleImportJobRetry = (importJob: ImportJob) => {
    navigate(
      `${clusterImportFile.build({
        clusterId,
        organizationId,
        projectId,
      })}?${JOB_ID_QUERY_PARAM}=${importJob.id}`,
    );
  };

  const canClearImportJobs = importJobs?.some(importJob =>
    ['FAILED', 'SUCCEEDED'].includes(importJob.status),
  );
  const clearImportJobs = () => {
    if (canClearImportJobs) {
      const jobsToBeDeleted = importJobs!
        .filter(importJob => ['FAILED', 'SUCCEEDED'].includes(importJob.status))
        .map(importJob => importJob.id);

      setImportJobsToBeCleared([...jobsToBeDeleted]);
      jobsToBeDeleted.forEach(async importJob =>
        handleImportJobDelete({ id: importJob }),
      );
    }
  };

  return (
    <ConstrainWidth>
      <SectionContainer className="absolute h-full w-full overflow-x-auto">
        <LoadingContainer
          loading={isLoadingImportJobs}
          render={() => {
            return (
              <>
                <div className="flex items-center justify-between pb-4">
                  <Heading level={Heading.levels.h2}>
                    <FormattedMessage id="cluster.clusterImport.title" />
                  </Heading>

                  <div className="flex gap-2">
                    {canClearImportJobs && (
                      <Popconfirm
                        title={formatMessage({
                          id: 'cluster.clusterImport.clearAllButtonConfirmText',
                        })}
                        placement="bottomRight"
                        onConfirm={() => clearImportJobs()}
                      >
                        <Button
                          disabled={importJobsToBeCleared.length > 0}
                          type={Button.types.BUTTON}
                          kind={Button.kinds.TERTIARY}
                        >
                          <FormattedMessage id="cluster.clusterImport.clearAllButton" />
                        </Button>
                      </Popconfirm>
                    )}
                    <Button
                      onClick={() => {
                        navigate(
                          clusterImportFile.build({
                            clusterId,
                            organizationId,
                            projectId,
                          }),
                        );
                      }}
                    >
                      <FormattedMessage id="cluster.clusterImport.addNewImportButton" />
                    </Button>
                  </div>
                </div>

                <ImportJobsList
                  importJobs={importJobs!}
                  onDelete={handleImportJobDelete}
                  onRetry={handleImportJobRetry}
                />
              </>
            );
          }}
        />
      </SectionContainer>
    </ConstrainWidth>
  );
}
export default ClusterImport;
