import useSWR from 'swr';
import {
  Bucket,
  Container,
  File,
  ImportJobId,
  Secret,
  SecretId,
  type ClusterId,
  type ImportJob,
  type OrganizationId,
} from 'types';

export const useGetOrganizationsIdFiles = (organizationId: OrganizationId) => {
  return useSWR<File[]>(
    organizationId ? `/api/v2/organizations/${organizationId}/files/` : null,
  );
};

export const useGetClustersIdImportJob = (
  clusterId: ClusterId | undefined,
  jobId: ImportJobId | null,
) => {
  return useSWR<ImportJob>(
    clusterId && jobId
      ? `/api/v2/clusters/${clusterId}/import-jobs/${jobId}/`
      : null,
  );
};

export const useGetClustersIdImportJobs = (clusterId: ClusterId) => {
  return useSWR<ImportJob[]>(
    clusterId ? `/api/v2/clusters/${clusterId}/import-jobs/` : null,
    {
      refreshInterval: data => {
        return data &&
          data.some(j => ['IN_PROGRESS', 'SENT', 'REGISTERED'].includes(j.status))
          ? 3 * 1000 // poll faster if imports are in progress
          : 10 * 1000;
      },
    },
  );
};

export const useGetOrganizationsIdSecrets = (organizationId: OrganizationId) => {
  return useSWR<Secret[]>(
    organizationId ? `/api/v2/organizations/${organizationId}/secrets/` : null,
  );
};

export const useGetIntegrationsAwsS3Buckets = (
  secretId: SecretId,
  endpointURL: string | undefined,
) => {
  return useSWR<{
    buckets: Bucket[];
    error?: string;
  }>(
    secretId
      ? `/api/v2/integrations/aws/s3-buckets/?secret_id=${secretId}&endpoint=${
          endpointURL ? encodeURI(endpointURL) : ''
        }`
      : null,
  );
};

export const useGetIntegrationsAwsS3objects = (
  secretId: SecretId,
  endpointURL: string | undefined,
  bucket: string,
  prefix: string | null = null,
) => {
  const params = new URLSearchParams({
    secret_id: secretId,
    bucket,
  });
  if (endpointURL) {
    params.append('endpoint', encodeURI(endpointURL));
  }
  if (prefix) {
    params.append('prefix', prefix);
  }
  return useSWR<{
    objects: string[];
    error?: string;
  }>(
    secretId && bucket
      ? `/api/v2/integrations/aws/s3-objects/?${params.toString()}`
      : null,
  );
};

export const useGetIntegrationsAzureBlobStorageContainers = (secretId: SecretId) => {
  return useSWR<{
    containers: Container[];
    error?: string;
  }>(
    secretId
      ? `/api/v2/integrations/azure/blob-storage-containers/?secret_id=${secretId}`
      : null,
  );
};

export const useGetIntegrationsAzureBlobs = (
  secretId: SecretId,
  containerName: string,
  prefix: string | null = null,
) => {
  const params = new URLSearchParams({
    secret_id: secretId,
    container_name: containerName,
  });
  if (prefix) {
    params.append('prefix', prefix);
  }
  return useSWR<{
    blobs: string[];
    error?: string;
  }>(
    secretId && containerName
      ? `/api/v2/integrations/azure/blobs/?${params.toString()}`
      : null,
  );
};
